import React from 'react';
import feet1 from "../../Images/feet/feet-page-1.webp";
import feet2 from "../../Images/feet/feet-page-2.webp";
import feet3 from "../../Images/feet/feet-page-3.webp";
import feet4 from "../../Images/feet/feet-page-4.webp";
import feet5 from "../../Images/feet/feet-page-5.webp";
import feet6 from "../../Images/feet/feet-page-6.webp";
import feet7 from "../../Images/feet/feet-page-7.webp";


const images = [
  { src: feet1, alt: 'feetl1' },
  { src: feet2, alt: 'feetl2' },
  { src: feet3, alt: 'feetl3' },
  { src: feet4, alt: 'feetl4' },
  { src: feet5, alt: 'feetl5' },
  { src: feet6, alt: 'feetl6' },
  { src: feet7, alt: 'feetl7' },
];

export const Feet = () => {
  const handleImageClick = (src) => {
    const link = document.createElement('a');
    link.href = src;
    link.download = src.split('/').pop(); // Use the image name for download
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  return (
    <div className="grid-parent">
    <div className="grid-container">
      {images.map((image, index) => (
        <div key={index} className="grid-item" onClick={() => handleImageClick(image.src)}>
          <img className="grid-img" src={image.src} alt={image.alt} />
        </div>
      ))}
    </div>
    </div>
  );
};

export default Feet;
