import React from 'react';
import cont1 from "../../Images/contour-line-drawings/contour-line-1.webp";
import cont2 from "../../Images/contour-line-drawings/contour-line-2.webp";
import cont3 from "../../Images/contour-line-drawings/contour-line-3.webp";
import cont4 from "../../Images/contour-line-drawings/contour-line-4.webp";

const images = [
  { src: cont1, alt: 'contl1' },
  { src: cont2, alt: 'contl2' },
  { src: cont3, alt: 'contl3' },
  { src: cont4, alt: 'contl4' },
];

export const ContourLines = () => {
  const handleImageClick = (src) => {
    const link = document.createElement('a');
    link.href = src;
    link.download = src.split('/').pop(); // Use the image name for download
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  return (
    <div className="grid-parent">
    <div className="grid-container">
      {images.map((image, index) => (
        <div key={index} className="grid-item" onClick={() => handleImageClick(image.src)}>
          <img className="grid-img" src={image.src} alt={image.alt} />
        </div>
      ))}
    </div>
    </div>
  );
};

export default ContourLines;
