import React from 'react';
import betsy1 from "../../Images/figure-drawing-2-min-gestures/betsy-2min-4-nov.webp";
import betsy2 from "../../Images/figure-drawing-2-min-gestures/betsy2-2min-dec.webp";
import betsy3 from "../../Images/figure-drawing-2-min-gestures/betsy4-2min-dec.webp";
import betsy4 from "../../Images/figure-drawing-2-min-gestures/betsy5-2min-dec.webp";
import betsy5 from "../../Images/figure-drawing-2-min-gestures/betsy5-2min.webp";
import brook1 from "../../Images/figure-drawing-2-min-gestures/brook-2minute-gesture-3-nov.webp";
import brook2 from "../../Images/figure-drawing-2-min-gestures/brook6-2min.webp";
import burt1 from "../../Images/figure-drawing-2-min-gestures/burt7-2min.webp";
import burt2 from "../../Images/figure-drawing-2-min-gestures/burt12-2min.webp";
import burt3 from "../../Images/figure-drawing-2-min-gestures/burt16-2min.webp";
import maddy1 from "../../Images/figure-drawing-2-min-gestures/maddy-2min-dec.webp";
import maddy2 from "../../Images/figure-drawing-2-min-gestures/maddy-2min.webp";
import maddy3 from "../../Images/figure-drawing-2-min-gestures/maddy-2minute-gesture-nov-6.webp";
import maddy4 from "../../Images/figure-drawing-2-min-gestures/maddy-2minute-gestures-2-nov.webp";
import maddy5 from "../../Images/figure-drawing-2-min-gestures/maddy-2minute-gestures-3-nov.webp";
import maddy6 from "../../Images/figure-drawing-2-min-gestures/maddy-2minute-gestures-4-nov.webp";
import maddy7 from "../../Images/figure-drawing-2-min-gestures/maddy-2minute-gestures-nov.webp";
import maddy8 from "../../Images/figure-drawing-2-min-gestures/maddy2-2min-dec.webp";
import maddy9 from "../../Images/figure-drawing-2-min-gestures/maddy2-2min.webp";
import nick1 from "../../Images/figure-drawing-2-min-gestures/nick-2min-dec.webp";
import nick2 from "../../Images/figure-drawing-2-min-gestures/nick-2minute-gestures-3-nov.webp";
import nick3 from "../../Images/figure-drawing-2-min-gestures/nick-2minute-gestures-4-nov.webp";
import nick4 from "../../Images/figure-drawing-2-min-gestures/nick-2minute-gestures-nov.webp";
import nick5 from "../../Images/figure-drawing-2-min-gestures/nick2-2min-dec.webp";

const images = [
  { src: betsy1, alt: 'b2min1' },
  { src: betsy2, alt: 'b2min2' },
  { src: betsy3, alt: 'b2min3' },
  { src: betsy4, alt: 'b2min4' },
  { src: betsy5, alt: 'b2min5' },
  { src: brook1, alt: 'br2min1' },
  { src: brook2, alt: 'br2min2' },
  { src: burt1, alt: 'bu2min1' },
  { src: burt2, alt: 'bu2min2' },
  { src: burt3, alt: 'bu2min3' },
  { src: maddy1, alt: 'mmin1' },
  { src: maddy2, alt: 'mmin2' },
  { src: maddy3, alt: 'mmin3' },
  { src: maddy4, alt: 'mmin4' },
  { src: maddy5, alt: 'mmin5' },
  { src: maddy6, alt: 'mmin6' },
  { src: maddy7, alt: 'mmin7' },
  { src: maddy8, alt: 'mmin8' },
  { src: maddy9, alt: 'mmin9' },
  { src: nick1, alt: 'nmin1' },
  { src: nick2, alt: 'nmin2' },
  { src: nick3, alt: 'nmin3' },
  { src: nick4, alt: 'nmin4' },
  { src: nick5, alt: 'nmin5' },

];

export const TwoMinuteGestures = () => {
  const handleImageClick = (src) => {
    const link = document.createElement('a');
    link.href = src;
    link.download = src.split('/').pop(); // Use the image name for download
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  return (
    <div className="grid-parent">
    <div className="grid-container">
      {images.map((image, index) => (
        <div key={index} className="grid-item" onClick={() => handleImageClick(image.src)}>
          <img className="grid-img" src={image.src} alt={image.alt} />
        </div>
      ))}
    </div>
    </div>
  );
};

export default TwoMinuteGestures;
