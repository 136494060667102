import React from 'react';
import coffee from "../../Images/graphic-design/typography/typography-coffee.webp";
import cookies from "../../Images/graphic-design/typography/typography-cookies.webp";
import halloween from "../../Images/graphic-design/typography/typography-halloween-photoshop.webp";






const images = [
  { src: coffee, alt: 'coffee1' },
  { src: cookies, alt: 'cookies1' },
  { src: halloween, alt: 'halloween1' },

];

export const Typography = () => {
  const handleImageClick = (src) => {
    const link = document.createElement('a');
    link.href = src;
    link.download = src.split('/').pop(); // Use the image name for download
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  return (
    <div className="grid-parent">
    <div className="grid-container">
      {images.map((image, index) => (
        <div key={index} className="grid-item" onClick={() => handleImageClick(image.src)}>
          <img className="grid-img" src={image.src} alt={image.alt} />
        </div>
      ))}
    </div>
    </div>
  );
};

export default Typography;
