import React from 'react';
import shadonly1 from "../../Images/shadow-only/shadow-only.webp";
import shadonly2 from "../../Images/shadow-only/shadow-only2.webp";
import shadonly3 from "../../Images/shadow-only/shadow-only3.webp";

const images = [
  { src: shadonly1, alt: 'shad-only1' },
  { src: shadonly2, alt: 'shad-only2' },
  { src: shadonly3, alt: 'shad-only3' },
];

export const ShadowOnly = () => {
  const handleImageClick = (src) => {
    const link = document.createElement('a');
    link.href = src;
    link.download = src.split('/').pop(); // Use the image name for download
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  return (
    <div className="grid-parent">
    <div className="grid-container">
      {images.map((image, index) => (
        <div key={index} className="grid-item" onClick={() => handleImageClick(image.src)}>
          <img className="grid-img" src={image.src} alt={image.alt} />
        </div>
      ))}
    </div>
    </div>
  );
};

export default ShadowOnly;
